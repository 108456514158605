var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("On-hold per medewerker")
            ])
          ]),
          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
            _c(
              "div",
              { staticClass: "elementPanel", attrs: { row: "", wrap: "" } },
              [
                _c(
                  "v-layout",
                  {
                    staticClass: "tw-mb-16",
                    attrs: { wrap: "", "align-baseline": "" }
                  },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", sm2: "" } },
                      [
                        _c(
                          "v-menu",
                          {
                            ref: "dialog",
                            attrs: {
                              "close-on-content-click": false,
                              lazy: "",
                              "nudge-right": "130",
                              "full-width": "",
                              "max-width": "290px",
                              "min-width": "290px"
                            }
                          },
                          [
                            _c("v-text-field", {
                              staticClass: "input input-date",
                              attrs: {
                                slot: "activator",
                                "hide-details": "",
                                placeholder: "dd-mm-jjjj",
                                label: "Datum vanaf"
                              },
                              slot: "activator",
                              model: {
                                value: _vm.date.formattedDate,
                                callback: function($$v) {
                                  _vm.$set(_vm.date, "formattedDate", $$v)
                                },
                                expression: "date.formattedDate"
                              }
                            }),
                            _c("v-date-picker", {
                              ref: "picker",
                              attrs: {
                                locale: "nl-nl",
                                min: "1910-01-01",
                                color: "#837f16",
                                "first-day-of-week": "1"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.fetchUsers()
                                }
                              },
                              model: {
                                value: _vm.date.selectedDate,
                                callback: function($$v) {
                                  _vm.$set(_vm.date, "selectedDate", $$v)
                                },
                                expression: "date.selectedDate"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", sm2: "" } },
                      [
                        !_vm.$store.state.Auth.hasRole("planning")
                          ? _c("v-autocomplete", {
                              staticClass: "with-border",
                              attrs: {
                                items: _vm.departments,
                                "item-value": "id",
                                "item-text": "name",
                                "hide-details": "",
                                label: "Afdelingen",
                                clearable: "",
                                multiple: ""
                              },
                              on: {
                                change: function($event) {
                                  return _vm.fetchUsers()
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "selection",
                                    fn: function(ref) {
                                      var item = ref.item
                                      var index = ref.index
                                      return [
                                        index === 0
                                          ? _c("span", [
                                              _vm._v(
                                                "Afdeling (" +
                                                  _vm._s(
                                                    _vm.selectedDepartments
                                                      .length
                                                  ) +
                                                  ")"
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2431312973
                              ),
                              model: {
                                value: _vm.selectedDepartments,
                                callback: function($$v) {
                                  _vm.selectedDepartments = $$v
                                },
                                expression: "selectedDepartments"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", sm2: "" } },
                      [
                        !_vm.$store.state.Auth.hasRole("planning")
                          ? _c("v-autocomplete", {
                              staticClass: "with-border",
                              attrs: {
                                items: _vm.userTypes,
                                "item-value": "key",
                                "item-text": "name",
                                "hide-details": "",
                                label: "Medewerker Type",
                                clearable: "",
                                multiple: ""
                              },
                              on: {
                                change: function($event) {
                                  return _vm.fetchUsers()
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "selection",
                                    fn: function(ref) {
                                      var item = ref.item
                                      var index = ref.index
                                      return [
                                        index === 0
                                          ? _c("span", [
                                              _vm._v(
                                                "Medewerker Type (" +
                                                  _vm._s(
                                                    _vm.selectedUserTypes.length
                                                  ) +
                                                  ")"
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2071479751
                              ),
                              model: {
                                value: _vm.selectedUserTypes,
                                callback: function($$v) {
                                  _vm.selectedUserTypes = $$v
                                },
                                expression: "selectedUserTypes"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", sm2: "" } },
                      [
                        _c("v-autocomplete", {
                          staticClass: "with-border",
                          attrs: {
                            items: _vm.onHoldReasons,
                            "item-value": "value",
                            "item-text": "name",
                            "hide-details": "",
                            label: "On-hold reden",
                            clearable: "",
                            multiple: ""
                          },
                          on: {
                            change: function($event) {
                              return _vm.fetchUsers()
                            }
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "selection",
                              fn: function(ref) {
                                var item = ref.item
                                var index = ref.index
                                return [
                                  index === 0
                                    ? _c("span", [
                                        _vm._v(
                                          "On-hold reden (" +
                                            _vm._s(
                                              _vm.selectedUserTypes.length
                                            ) +
                                            ")"
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.selectedOnHoldReasons,
                            callback: function($$v) {
                              _vm.selectedOnHoldReasons = $$v
                            },
                            expression: "selectedOnHoldReasons"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.isLoading
                  ? _c("LoaderCard", {
                      attrs: {
                        flat: "",
                        type: "spinner--center",
                        minHeight: "300px"
                      }
                    })
                  : _vm._e(),
                !_vm.isLoading
                  ? _c("WeekStatisticsView", {
                      attrs: { items: _vm.users, columns: _vm.columns }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }